import { React, useEffect, useState } from "react";
import ActiveCredits from './active-credits/activeCredits';
import Balance from './balance';
import useStylesCredit from "../../utils/themes/ThemeCredit";
import {Grid, Divider} from "@mui/material";
import { useCreditContext } from "../../utils/providers/CreditProvider";
import { useAPIContext } from '../../utils/providers/APIProvider';
import PropTypes from 'prop-types';

const HomeCredit =({props, validateError, loading, email}) =>{
  const theme = useStylesCredit();
  const [hasResponseUser, setHasResponseUser] = useState(false);
  const { setIsAbbreviate, setIsOverLimit, setDocumentStatus, setHasActiveCredit, setMsisdn, client } = useCreditContext();
  const {
    get_user
  } = useAPIContext();

  useEffect(async () => {
    const responseUser = await get_user({param: `email=${email}`});
    if (responseUser.status === 200) {
      setIsAbbreviate(responseUser.data?.is_abbreviate === "true");
      setIsOverLimit(responseUser.data?.is_over_limit);
      setDocumentStatus(responseUser.data?.document_status);
      setHasActiveCredit(responseUser.data?.has_active_credit);
      setMsisdn(responseUser.data?.["Negocio"]["owner-information"]["active_cell_number"]);
      setHasResponseUser(true);
    }
  }, []);

  return hasResponseUser && (
    <Grid container className={theme.containerHomeCredit} justifyContent="space-between">
      <Grid item xs={12} sm={3}>
        <Balance props={props} />
      </Grid>
      <Divider orientation="vertical" flexItem>
      </Divider>
      <Grid item xs={12} sm={8}>
        <ActiveCredits client={client.client_id} limit={'10'} validateError={validateError} loading={loading}/>
      </Grid>
    </Grid>
  )
}

HomeCredit.propTypes = {
    props: PropTypes.any.isRequired,
    validateError: PropTypes.any.isRequired,
    loading: PropTypes.any.isRequired,
    email: PropTypes.any.isRequired,
    client_id: PropTypes.any.isRequired
};
export default HomeCredit;