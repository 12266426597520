import React, {useState, useEffect} from "react";
import GenericForm from "../genericForm"
import dataJson from "../config/signUp-email.json";
import {schema, getYup} from "../../utils/yup";
import {
    send_email_create_account,
    get_user_account_valid,
    get_mts_account
} from "../../actions/axios-api";
import SendEmail from "./sendEmail";

export default function VerifyEmail({setLoading, showLoginRedirect=true, handleBehavior=()=>{}, origin="", tokenID=""}) {
    const [data, setData] = useState(dataJson.forms);
    const [validates, setValidates] = useState({});
    const [screen, setScreen] = useState(false);
    const [email, setEmail] = useState("");

    const send_email = async (values, isLegacyUser, idPdv, signedContract) => {
        if (isLegacyUser) {
            values.isLegacyMomoUser = isLegacyUser
            values.id_pdv = idPdv
        }
        values.signedContract = signedContract
        values.origin = origin
        values.tokenID = tokenID
        const response = await send_email_create_account(values)
        if (response.status === 200) {
            setLoading(false);
            setScreen(true);
        }
    }

    const handleSubmit = async (values) => {
        window.analytics.track("PAGEVIEW_SIGNUP",{
            page: "WC_Signup",
            journey: "WC_Onboarding",
            button_name: "continuar",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        setLoading(true);
        setEmail(values)

        const response_mts = await get_mts_account(`email=${values["email"]}`)
        if(response_mts.status!==200){
            const responseAccountValid = await get_user_account_valid(`email=${values.email}`)
            let isLegacyUser = responseAccountValid?.data?.valid ? responseAccountValid.data.valid : false;
            let signedContract = responseAccountValid?.data?.signedContract
            let idPdv = responseAccountValid?.data?.id_pdv
            if ((responseAccountValid.data.msg === "record not foud") || (isLegacyUser)){
                await send_email(values, isLegacyUser, idPdv, signedContract)
            }
        }
        handleBehavior(values)
        let newData = {...data};
        newData.fields[0].props.conditions[0].created = true;
        setData(newData);
        setLoading(false);
    };

    useEffect(() => {
        let _validates = {};
        data.fields.forEach(({props, validations}) => {
            _validates[props.name] = schema(validations, props.type);
        });
        setValidates(_validates);
        window.analytics.page("PAGEVIEW_SIGNUP", {
            journey: 'WC_Onboarding',
            name: 'PAGEVIEW_PASSWORD_CREATION',
            page: 'WC_Signup',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        })
        let formStructure = {...data};
        formStructure.footer.created = showLoginRedirect;
        setData(formStructure);
    }, []);

    return (
        <>{screen ? <SendEmail email={email} setLoading={setLoading}/> :
            <GenericForm
            form={data}
            initialValue={{}}
            validates={getYup(validates)}
            onSubmit={handleSubmit}
            isDefault={false}
            setLoginError={() => {}}
            loginError={false}
            disabledButton={false}
            setDisabledButton={() => {}}
            />}
        </>
    );
}
