import countryCode from '../utils/translations/countryCode.json'
import { formatNumber } from './formatNumber';
import formatter from './translations/formatter';
import { getFormattedDate, getFormattedDate_, getDate_, getDate } from "./functions/utils";

const _countryCode = {
    py: {
        currency: 'PYG',
        format: 'es-PY'
    },
    hn: {
        currency: 'HNL',
        format: 'es-HN'
    },
    gt: {
        currency: 'GTQ',
        format: 'es-GT'
    },
    bo: {
        currency: 'BOB',
        format: 'es-BO'
    },
    sv: {
        currency: 'USD',
        format: 'es-SV'
    },
}

const schedulePeriod = {
    'DAYS-1': 'ConfigureCredit.day',
    'DAYS-more': 'ConfigureCredit.days',
    'DAYS-week': 'ConfigureCredit.week',
    'DAYS-weeks': 'ConfigureCredit.weeks',
    'MONTHS-1': 'ConfigureCredit.month',
    'MONTHS-more': 'ConfigureCredit.months',
    'YEARS-1': 'ConfigureCredit.year',
    'YEARS-more': 'ConfigureCredit.years',
    'WEEKS-1': 'ConfigureCredit.week',
    'WEEKS-more': 'ConfigureCredit.weeks'
}
const loanName = {
    "Sub-crédito WC e-pin V2": "Epin",
    "Sub-crédito WC e-pin": "Epin",
    "Sub-crédito WC e-money": "Emoney",
    "Sub-crédito WC e-money V2": "Emoney",
    "Sub-crédito WC e-pin intereses 3 days": "Epin",
    "Sub-crédito WC e-pin intereses 7 days": "Epin",
    "Sub-crédito WC e-pin intereses 14 days": "Epin",
    "Sub-crédito WC e-pin intereses 1 month": "Epin",
    "Sub-crédito WC e-money intereses 3 days": "Emoney",
    "Sub-crédito WC e-money intereses 7 days": "Emoney",
    "Sub-crédito WC e-money intereses 14 days": "Emoney",
    "Sub-crédito WC e-money intereses 1 month": "Emoney",
    "Sub-crédito WC e-pin intereses 3 días": "Epin", 
    "Sub-crédito WC e-money intereses 3 días": "Emoney"
};
const loanDue = {
    "ACTIVE_IN_ARREARS": true,
    "ACTIVE": false
}
const days = {
    "DAYS": 1,
    "MONTHS": 30,
    "YEARS": 365,
    "WEEK": 7,
}
const monthNames = [
    "Month.January",
    "Month.February",
    "Month.March",
    "Month.April",
    "Month.May",
    "Month.June",
    "Month.July",
    "Month.August",
    "Month.September",
    "Month.October",
    "Month.November",
    "Month.December"
];
function get_product(productArr, id) {
    return productArr.filter(product => product['productName'] === id)[0];
}
Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
function formatSimpleCard(loan, route, product){
    let infoCard = formatCard(loan, route, product)

    let TempDate = loan['creationDate'].split('T')[0].split('-');
    let date = `${TempDate[2]}/${TempDate[1]}/${TempDate[0]}`
    infoCard['date'] = date;
    return infoCard
}
function formatCard(loan, route, product = []) {
    let type = loanName[loan['loanName']];
    let currencyCountry = loan['currency']['currencyCode'];
    let amount = new Intl.NumberFormat(countryCode[currencyCountry]).format(loan['loanAmount']);
    let date = '';
    let productDetail;
    let date_ = ''; 
    if (product.length > 0) {

        let dateObj = new Date(loan['disbursementDetails']['disbursementDate']);
        productDetail = get_product(product, loan['loanName'])
        date = dateObj.addDays(days[productDetail['repaymentPeriodUnit']] * productDetail['defaultRepaymentPeriodCount']).toLocaleDateString('en-US', {
            timeZone: 'America/Asuncion'
        });
        date = getFormattedDate(date)
        date_ = getDate(date)
    } else {
        let TempDate = loan['disbursementDetails']['disbursementDate'].split('T')[0].split('-');
        date = `${TempDate[2]}/${TempDate[1]}/${TempDate[0]}`
        date = getFormattedDate_(date)
        date_ = getDate_(date)
    }

    let due = loanDue[loan['accountState']];
    let url = route

    let card = {
        type: type,
        amount: amount,
        date: date,
        currencyCountry: currencyCountry,
        due: due,
        url: url,
        loan: loan,
        product: product,
        productDetail: productDetail,
        originalDate: date_
    };
    return card;
}
function scheduleInfo(product) {

    let key = '';
    let period = product['defaultRepaymentPeriodCount'];
    let unit = product['repaymentPeriodUnit'];

    let subkey = '';

    if (unit === 'DAYS') {
        if (period < 7) {

            subkey = period === 1 ? '1' : 'more';
            key = `${unit}-${subkey}`;
        } else {

            subkey = period === 7 ? 'week' : 'weeks';
            period = period / 7;
            key = `${unit}-${subkey}`;

        }
    } else {
        subkey = period === 1 ? '1' : 'more';
        key = `${unit}-${subkey}`;
    }
    return { "period": period, "unit": `${schedulePeriod[key]}` };
}
const resetNumber = (value, _) => {
    if (typeof value == 'string') {
        return parseInt(value.replaceAll(_, ''));
    } else {
        return value;
    }
}
const _days = (e) => {
    let period = e['period']
    let date = new Date(e['today']);
    date.setDate(date.getDate() + period);
    return date;
}
const _weeks = (e) => {
    let period = e['period'] * 7;
    let date = new Date(e['today']);
    date.setDate(date.getDate() + period);
    return date;
}
const _months = (e) => {
    let period = e['period']
    let date = new Date(e['today']);
    date.setMonth(date.getMonth() + period);
    return date;
}
const _years = (e) => {
    let date = new Date(e['today']);
    let period = e['period']
    date.setYear(date.getYear() + period);
    return date;
}

const getLocaleDateString = (date, region, options) => {
    return date.toLocaleDateString(region, options);
}

const dictionaryFunctionAddPeriod = {
    'DAYS': _days,
    'WEEKS': _weeks,
    'MONTHS': _months,
    'YEARS': _years
}
const calculateDueDate = (product) => {
    let today = new Date();
    let num = product['defaultRepaymentPeriodCount'];
    let unit = product['repaymentPeriodUnit'];
    let _function = dictionaryFunctionAddPeriod[unit];
    let new_date = _function({
        'today': today,
        'period': num
    });
    let options = {
        timeZone: 'America/Asuncion'
    }
    const day = getLocaleDateString(new_date, 'en-US', { ...options, day: '2-digit' });
    const month = getLocaleDateString(new_date, 'en-US', { ...options, month: '2-digit' });
    const year = getLocaleDateString(new_date, 'en-US', { ...options, year: 'numeric' });
    return `${day}/${month}/${year}`;
}
const calculateDueDate_loan = (loan) => {
    if (loan['disbursementDetails']) {
        let dateObj = new Date(loan['disbursementDetails']['disbursementDate'])
        let num = loan['scheduleSettings']['repaymentPeriodCount'];
        let unit = loan['scheduleSettings']['repaymentPeriodUnit'];
        let _function = dictionaryFunctionAddPeriod[unit];
        let new_date = _function({
            'today': dateObj,
            'period': num
        });
        return new_date.toLocaleDateString('en-US', {
            timeZone: 'America/Asuncion'
        })
    }
    else {
        return '00/00/00'
    }
}
const filterProduct = (products, id) => {
    return products.filter(product => product['id'] === id)[0];
}
const formatNum = (amount, currency) => {
    return formatNumber(countryCode[currency], amount);
}

const inputValidates = (value, format, min, max, currency) => {
    value = resetNumber(value, '.');
    let _value = value;

    if (value < min) {
        _value = min;
    }
    if (value > max) {
        _value = max;
    }
    if (format) {
        _value = formatNum(_value, currency)
    }

    return _value;
}
const _inputValidates = (value, format) => {
    value = resetNumber(value, '.');
    let _value = value;

    if (format) {
        _value = formatter(_value)
    }

    return _value;
}
const filterProductId = (products, id) => {
    return products.filter(product => product['productId'] === id) === 0;
}

const _formatter = (amount, country = 'py') => {
    const formatter = new Intl.NumberFormat(_countryCode[country].format);
    return (
        formatter.format(amount)
    );
};


const getMonth = () => {
    let today = new Date();
    today.setMonth(today.getMonth() - process.env.REACT_APP_FILTER);

    let year = today.getFullYear();
    let month = today.getMonth() + 1; 

    return {
        'month': month - 1,
        'year': year
    }; 
}


export {
    getMonth,
    formatCard,
    scheduleInfo,
    resetNumber,
    calculateDueDate,
    filterProduct,
    inputValidates,
    filterProductId,
    _inputValidates,
    _formatter,
    calculateDueDate_loan,
    monthNames,
    formatSimpleCard
};