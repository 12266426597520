import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { useCreditContext } from '../../utils/providers/CreditProvider';
import { approvalType } from "../../constants/WowMoment";
import { Stack } from "@mui/material";
import { ThemeBanner } from "../../utils/themes/ThemeBanner";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PaymentWow from "../payment/PaymentWow/PaymentWow";
import WowMomentComponent from './wow-moment/wowMomentComponent';
import UserInfoHeader from './userInfoHeader';
import Loading from "../../views/Loading";
import { useAPIContext } from '../../utils/providers/APIProvider';
export default function WowMoment() {
    const [loading, setLoading] = useState(false);
    const [pageEvent, setPageEvent] = useState(true);
    const navigate = useNavigate();
    const bannerStyles = ThemeBanner();
    const theme = useTheme();
    let arrears;
    let {
        productName,
        credit,
        client,
        setClient,
        paymentType,
        paymentOptions,
        payment,
        setNavigateConfig,
        isAbbreviate,
        isOverLimit,
        documentStatus,
        clientId,
        disabledCredit
    } = useCreditContext();
    const {
        credit_line_data
    } = useAPIContext(); 
    let matches = useMediaQuery(theme.breakpoints.up("sm"));

    if (client?.arrears) {
        arrears = client?.arrears?.toLowerCase?.() === 'true'
    } else {
        arrears = false
    }

    if (!paymentType) {
        paymentType = approvalType.available

    }
    if((paymentType === approvalType?.optional || paymentType === approvalType?.available) && pageEvent){
        window.analytics.page("PAGEVIEW_PAYMENT_WOW_PENDING", {
            page: "WC_Payment_WOW_Pending",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Pagar créditos"
        });
        setPageEvent(false)
    }
    const validateCredit = async () => {
        const response = await credit_line_data({params:`email=${client?.email}&client_id=${clientId}`});
        setClient(response.data);
    }

    const setNaviagteGoToHome = async () => {
        setNavigateConfig({ "back": "/Credit" });
    }

    useEffect(async () => {
        setLoading(true)
        await setNaviagteGoToHome()
        if (paymentType === approvalType?.available) {
            await validateCredit();
            setLoading(false)
        }
       
    }, []);

    const onSubmitApplyCredit = () => {
        window.analytics.track('REQUEST_WOW_AGAIN', {
            page: 'WC_Request_wow',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
            button_name: "Solicitar otro Credito"
        });
        navigate('/Product')
    }

    const onSubmitGotoHome = () => {
        window.analytics.track('REQUEST_HOME', {
            page: 'WC_Request_wow',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
            button_name: "Volver al inicio"
        });
        navigate('/Credit', { state: { email: client?.email } })
    }

    const onSubmitTotalPayment = async () => {
        window.analytics.track("PAYMENT_WOW_OK_PAY", {
            page: "WC_Payment_WOW_OK",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Solicitar crédito"
        });
        navigate("/Product")
        await validateCredit();
    };

    const onCancelTotalPayment = async () => {
        window.analytics.track("PAYMENT_WOW_OK_PAY", {
            page: "WC_Payment_WOW_OK",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Volver al inicio"
        });
        navigate("/Credit")
    };

    const onSubmitPendingCredit = async () => {
        window.analytics.track("PAYMENT_WOW_OK_PAY", {
            page: "WC_Payment_WOW_Pending",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Pagar créditos"
        });
        navigate("/Payment")
        await validateCredit();
    };

    const onCancelCancelCredit = async () => {
        window.analytics.track("PAYMENT_WOW_OK_HOME", {
            page: "WC_Payment_WOW_Pending",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Pagar créditos"
        });
        navigate("/Credit")
    };

    const onSubmitDuePayment = async () => {
        window.analytics.track("PAYMENT_WOW_ARREARS_PAY", {
            page: "WC_Payment_WOW_Arrears",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Pagar créditos"
        });
        navigate("/Payment")
        await validateCredit();
    };

    const onCancelDuePayment = () => {
        window.analytics.track("PAYMENT_WOW_ARREARS_HOME", {
            page: "WC_Payment_WOW_Arrears",
            journey: "WC_Loans",
            product_group: "Lending",
            product_name: "Working_Capital",
            button_name: "Pagar créditos"
        });
        navigate("/Credit")
    };

    const getPaymentResume = () => {
        const response = {}
        const totalOptions = paymentOptions[approvalType?.total]
        let duePending = 0
        let creditPending = 0
        if (paymentType === approvalType?.required || paymentType === approvalType?.optional) {
            if (paymentType === approvalType?.required) {
                const requiredOptions = paymentOptions[approvalType?.required]
                duePending = totalOptions?.amount - requiredOptions?.amount
                creditPending = totalOptions?.length - requiredOptions?.length
                response['duePending'] = duePending
                response['creditPending'] = creditPending
                return response
            }
            if (paymentType === approvalType.optional) {
                let creditPay = 0
                let totalAmount = 0
                payment.forEach(element => {
                    if (element.totalAmount === element.loanAmount) {
                        creditPay += 1
                        totalAmount = totalAmount + element.totalAmount
                    } else {
                        creditPending += 1
                        totalAmount = totalAmount + element.totalAmount
                    }
                });

                response['duePending'] = totalOptions?.amount - totalAmount
                response['creditPending'] = totalOptions?.length - creditPay
                response['creditsPaid'] = creditPay
                response['totalAmount'] = totalAmount
                return response
            }
        }
    }

    const canCredit = () => {
        return !(isAbbreviate && isOverLimit && ["rejected", "pending"].includes(documentStatus))
    }

    return (
        <>
            {paymentType === approvalType.available && (
                <>
                    {loading && <Loading />}
                    <Stack style={{ display: (loading) ? "none" : "block" }} className={bannerStyles.bannerWowMomentOptional}>
                        <UserInfoHeader userdata={client} visibilityContent={false} />
                        <Stack className={bannerStyles.bannerWowMomentCard}>
                            <WowMomentComponent
                                onSubmit={onSubmitApplyCredit}
                                onCancel={onSubmitGotoHome}
                                client={client}
                                credit={credit}
                                matches={matches}
                                productName={productName}
                                disableCreditButton={disabledCredit}
                            />
                        </Stack>
                    </Stack>
                </>
            )}

            {paymentType === approvalType.total && (
                <Stack className={bannerStyles.bannerWowMomentOptional}>
                    <UserInfoHeader userdata={client} visibilityContent={false} />
                    <Stack className={bannerStyles.bannerWowMomentCard}>

                        <PaymentWow
                            onSubmit={onSubmitTotalPayment}
                            canCredit={canCredit()}
                            onCancel={onCancelTotalPayment}
                            amount={paymentOptions[paymentType]?.amount}
                            duePending={0}
                            paymentType={paymentType}
                        />
                    </Stack>
                </Stack>
            )}

            {paymentType === approvalType.optional && (
                <Stack className={bannerStyles.bannerWowMomentOptional}>
                    <UserInfoHeader userdata={client} visibilityContent={false} />
                    <Stack className={bannerStyles.bannerWowMomentCard}>
                        <PaymentWow
                            onSubmit={onSubmitPendingCredit}
                            onCancel={onCancelCancelCredit}
                            amount={getPaymentResume()?.totalAmount}
                            creditsPaid={getPaymentResume()?.creditsPaid}
                            isAccountActive={arrears}
                            creditPending={getPaymentResume()?.creditPending}
                            duePending={getPaymentResume()?.duePending}
                            paymentType={paymentType}
                        />
                    </Stack>
                </Stack>
            )}

            {paymentType === approvalType.required && (
                <Stack className={bannerStyles.bannerWowMomentOptional}>
                    <UserInfoHeader userdata={client} visibilityContent={false} />
                    <Stack className={bannerStyles.bannerWowMomentCard}> 
                        <PaymentWow
                            onSubmit={onSubmitDuePayment}
                            onCancel={onCancelDuePayment}
                            amount={paymentOptions[paymentType]?.amount}
                            creditsPaid={paymentOptions[paymentType]?.length}
                            creditPending={getPaymentResume()?.creditPending}
                            duePending={getPaymentResume()?.duePending}
                            paymentType={paymentType}
                            isAccountActive={arrears}
                        />
                    </Stack>
                </Stack>
            )}
        </>
    );
}
