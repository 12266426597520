import React, {Fragment, useEffect} from "react";
import dataJson from "../config/signUp-verify.json";
import GenericForm from "../genericForm"
import {send_email_create_account} from "../../actions/axios-api";

export default function SendEmail({email, setLoading}) {
  useEffect(async () => {
    window.analytics.page('PAGEVIEW_SIGNUP_STARTED', {
      page: 'WC_Signup_success',
      journey: 'WC_Onboarding',
      product_group: 'Lending',
      product_name: 'Working_Capital',
    });
  }, []);
  const handleSubmit = async () => {
    setLoading(true);
    window.analytics.track('SIGNUP_RESEND', {
      page: 'Signup_WC_success',
      journey: 'WC_Onboarding',
      button_name: 'Reenviar',
      product_group: 'Lending',
      product_name: 'Working_Capital',
    });
    await send_email_create_account(email)
    setLoading(false); 
  };

  return (
      <Fragment>
        <GenericForm
            form={dataJson.forms}
            initialValue={{}}
            validates={""}
            onSubmit={handleSubmit}
            isDefault={true}
        />
      </Fragment>
  );
}
