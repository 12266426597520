import { Grid, Box } from '@mui/material';
import { React, useEffect, useState, Fragment } from "react";
import CardCredit from './card-credit';
import { formatCard } from '../../../utils/getCardInfo';
import Button from '../../../components/inputs/button';
import themeActiveCredit from '../../../utils/themes/ThemeActiveCredit';
import { useTranslation } from "react-i18next";
import { useNavigate} from "react-router-dom";
import CreateAccount from '../../../assets/generic/CreateAccount.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAPIContext } from '../../../utils/providers/APIProvider';

const ActiveCredits = ({client, limit, validateError, loading }) => {
  const [credits, setCredits] = useState([]);
  const [allcredits, setallcredits] = useState([])
  const [products, setProducts] = useState([]);
  const styles = themeActiveCredit();
  const [t] = useTranslation("global");
  const [viewAll, setViewAll] = useState(false)
  const theme = useTheme();
  const navigate = useNavigate();
  const {get_all_credits} = useAPIContext();

  let buttonConfig = {
    btn: {
      type: 'button',
      color: 'primary',
      name: 'next',
      "styles": {
        "height": "48px",
        "fontFamily": "Readex Pro"
      },
      "styleClassName": "action-button-pay",
      label: t("Credit.creditActive.payloadAll"),
    },
    btnNext: () => onPayment(),


  }
 
  const buildCard = (loan, product) => {
    let cardInfo = formatCard(loan, '/Details', product);
    return <Grid item xs={12} sm={6} className={styles.gridLoan} ><CardCredit {...cardInfo} /></Grid>
  }

  let matches = useMediaQuery(theme.breakpoints.down('sm'))

  const getCredits = async () => {
    loading(true)
    const response = await get_all_credits({params: { limit: limit }}, client);
    if (response.status === 400) {
      loading(false)
      validateError(true)
    }
    else {
      setViewAll(response.data.loans.length > 0);
      setProducts(response.data.products_details);
      setallcredits(response.data.loans);
      matches? setCredits(allcredits.slice(0, 3)) : setCredits(allcredits)
      loading(false)
    }
  };

  const onPayment = () => {
    window.analytics.track('HOME_PAY_START', {
      page: 'WC_Home',
      journey: 'WC_Loans',
      product_group: 'Lending',
      product_name: 'Working_Capital',
      button_name: 'Confirmar'
    });
    navigate("/Payment")
  };

  const onAllCredits = () =>{
    window.analytics.track('HOME_LOANS_VIEWALL', {
      page: 'WC_Home',
      journey: 'WC_Loans',
      product_group: 'Lending',
      product_name: 'Working_Capital',
      link_name: 'Inicia Sesíon'
    })
    navigate('/AllCredits',
        {
          screen:"AllCredits",
          state:{clientid: client}
        }
    )
  }

  useEffect(() => {
    getCredits();
  }, []);

  useEffect(() => {
    matches? setCredits(allcredits.slice(0, 3)) : setCredits(allcredits)
  }, [matches, allcredits]);

  const imageMessage = () => {
    return <Fragment>
      <Grid item xs={12} className={styles.gridMessage} >
        <img
          className={styles.imageMessage}
          src={CreateAccount}
          alt={'CreateAccount'}
        />
      </Grid>
      <Grid item xs={12} className={styles.textMessage}>
        {t("Credit.creditActive.message")}
      </Grid>
    </Fragment>
  }

  const linkComponent = () => {
    return <div className={styles.divViewAll}>
      <p
      className={styles.textviewAll}
      onClick={onAllCredits}
      style={{textDecorationColor: "#FABF00"}}>
    {t('Credit.creditActive.viewAll')}
  </p></div>
  }

  return (
    <Box>
      <Grid container className={styles.gridTitleAndLink}>
        <Grid item xs={8} className={styles.gridTitle}>
          {t('Credit.creditActive.activeCredits')}
        </Grid>
        <Grid item xs={4}>
          {viewAll && linkComponent()}
        </Grid>
      </Grid>
      <Grid
        container
        className={styles.gridLoan}
        spacing={2}
      >
        {viewAll ? credits.map((credit) => {
          return buildCard(credit, products)
        }) : imageMessage()}
      </Grid>
      {viewAll && <div style={{ marginTop: "20px" }} id="div buttton">
        <Grid container spacing={2} className="grid-buttons">

          <Grid
            item
            xs={12}
            md={12}
            key={'button-credit'}
          >
            <div>
              <Button {...buttonConfig} />
            </div>
          </Grid>

        </Grid>
      </div>}
    </Box>
  )
}
export default ActiveCredits;