

const selectScreen = async (userInfo, navigate) => {
    const documents = userInfo.Negocio?.["documents"] ? userInfo.Negocio?.["documents"] : false
    let rejected, pending = false;
    let screen = '/Remedy';

    if (documents) {
        for (const document of Object.values(documents)) {
            if (document.status === 'rejected') {
                rejected = true
            } else if (document.status === 'pending') {
                pending = true
            }
        }
        if (rejected && !pending) {
            screen = '/Remedy'
        } else if (pending) {
            screen = '/PendingDocuments'
        } else {
            return navigate("ScreenMessage.Contract.")
        }
    }
    return screen
}

export {
    selectScreen
}