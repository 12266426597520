import React, {Fragment, useEffect, useState} from "react";
import {Button, Checkbox, Grid, Link, Typography } from "@mui/material";
import {useTranslation} from "react-i18next";
import OnBoardingLayout from "../utils/on-boarding-layout";
import {getContractHtml} from "../utils/translations/contracts";
import {getCountry} from "../utils/getCountry";
import signContract from "../assets/generic/signContract.svg";
import {getAccount, getDataContract} from "../utils/getKYMdata";
import {
    contract_create,
    contract_sign,
    create_account,
    validate_token,
    validate_msisdn_exist,
} from "../actions/axios-api";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import MuiTableCell from "@material-ui/core/TableCell";
import {withStyles} from "@material-ui/core/styles";
import themeContract from "../utils/themes/ThemeContract";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useLocation, useNavigate} from "react-router-dom";
import MessageError from "../components/loader/messageError";
import Loading from "./Loading";
import {useCreditContext} from "../utils/providers/CreditProvider";
import DialogTermsConditions from "../components/contract/dialogTermsConditions";


const TableCellSyle = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

function ContractScreen({setLoading, setError}) {

    const { origin, setAuthToken } = useCreditContext();
    const location = useLocation();
    const kym_data = location.state == null ? {} : location.state.data;
    const email = location.state == null ? {} : location.state.email;
    const password = location.state == null ? {} : location.state.password;
    const token = location.state == null ? {} : location.state.token;
    const tokenID = location.state == null ? {} : location.state.tokenID;
    const [checked, setChecked] = useState(false);
    const [checkNoticePrivacy, setCheckNoticePrivacy] = useState(false);
    const [checkTermsConditions, setTermsConditions] = useState(false);
    const [showDialog, setShowDialog] = useState(true);
    const [isMtsUser, setIsMtsUser] = useState();
    const [dataContract, setDataContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [country, setCountry] = useState("");
    const styles = themeContract();
    const theme = useTheme();
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
    ];

    const sizesGrid = {
        gridTCtrue: 12,
        gridButtonContainertrue: 12,
        gridButtonMBtrue: 9,
        gridButtontrue: 4,
        gridJustifytrue: 'flex-end',
        gridButtonMTtrue: 1,

        gridTCfalse: 10,
        gridButtonContainerfalse: 2,
        gridButtonMBfalse: 1,
        gridButtonfalse: 12,
        gridJustifyfalse: 'center',
        gridButtonMTfalse: 8,
    }
    const sizeGridController = (type, xs) => {
        let key = type + xs.toString();
        return sizesGrid[key];
    }
    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const due_year = year + 1; // 1 year later
    const timestamp = year + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + day.toString().padStart(2, '0') + " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0');

    const HtmlText = (code) => {
        return <div dangerouslySetInnerHTML={{__html: code}}/>;
    };

    const handleNext = async () => {
        if ((!isMtsUser ) &&  !(checkNoticePrivacy && checkTermsConditions)) {
            setShowDialog(true)
            return;
        }

        await window.analytics.track("KYA_CONTRACTS_NEXT", {
            page: "WC_KYA_Contracts",
            journey: "WC_Onboarding",
            button_name: "Confirmar",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        setLoading(true);

        let data = {
            "id": dataContract?.id_pdv,
            "email": dataContract?.email,
            "name": dataContract?.firstname,
            "lastname": dataContract?.lastname,
            "status": "true",
            "e_pin": dataContract?.cell_number_epin,
            "e_money": dataContract?.cell_number_emoney,
            "contact":  dataContract?.contact ? dataContract.contact.replace(/-/g, "") :"0000000000", 
            "is_abbreviate": dataContract?.is_abbreviate,
            "is_mts_user": isMtsUser,
            "check_notice_privacy": checkNoticePrivacy,
            "check_terms_conditions": checkTermsConditions
        }

        const responseSign = await contract_sign(data);
        if (responseSign.status !== 200) {
            showErrorPage("contract_sign response with error");
            return;
        }

        const responseContract = await contract_create(dataContract);
        if (responseContract.status !== 200) {
            showErrorPage("contract_create response with error");
            return;
        }

        const responseAccount = await create_account(account);

        if (responseAccount.status !== 200) {
            showErrorPage("contract_create response with error");
            return;
        }

        await window.analytics.page("CREATE_CONTRACT_SUCCESS", {
            page: "WC_Contracts_success",
            journey: "WC_Onboarding",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        await validate(true);
        setLoading(false);
        await goToMessageScreen(true);
    };

    const validate = async (isDeleted) => {
        let data = {"isDeleted": isDeleted, "token": token}
        await validate_token(data)
    }

    async function goToMessageScreen(ok) {
        let tPrefix = "ScreenMessage.Contract.";
        let title = "Title";
        let subTitle = "SubTitle";
        let image = "Image";

        if (!ok) {
            title = "TitleError";
            subTitle = "SubTitleError";
            image = "ImageError";
        }

        navigate("/MessageScreen", {
            screen: "MessageScreen",
            state: {
                type: {
                    data: {
                        image: t(tPrefix + image),
                        title: t(tPrefix + title),
                        subTitle: t(tPrefix + subTitle),
                    },
                },
            },
        });
    }

    const showErrorPage = async (msg) => {
        await window.analytics.track("KYA_CONTRACTS_FAIL", {
            page: "WC_KYA_Contracts",
            journey: "WC_Onboarding",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        goToMessageScreen(false);
    }

    const handleChange = (value, setValue) => {
        setValue(value);
    };

    async function getDataKym() {
        setDataContract(getDataContract(kym_data));
        setAccount(getAccount(kym_data, email, password, origin));
        setLoading(false);

    }

    const validateMtsUSer = async () => {
        const response = await validate_msisdn_exist(kym_data.Negocio?.["owner-information"]["active_cell_number"])
        setIsMtsUser(response.data.have_account)
    }

    useEffect(async () => {
        setLoading(true)
        await window.analytics.page("PAGEVIEW_KYA_CONTRACTS", {
            page: "WC_KYA_Contracts",
            journey: "WC_Onboarding",
            product_group: "Lending",
            product_name: "Working_Capital"
        });
        await validateMtsUSer()
        setCountry(getCountry());
        getDataKym();
    }, []);

    const getIdClientMts = (metadata) => {
        for (const item of metadata) {
            if (item.key === "CLIENT_ID") {
                return item.value;
            }
        }
    };

    let matches = useMediaQuery(theme.breakpoints.down('sm'))
    return dataContract && (
        <Fragment>
            {/* <GenericBackdrop open={loader} /> */}
            <Grid container className={styles.containerView} mt={12}>
                <Grid container className={styles.contentView}>
                    <Grid container direction="row">
                        <Grid item xs={9}>
                            <Grid container direction="row">
                                <Grid item>
                                    <Typography
                                        className="contractTitle"
                                        variant="title"
                                        component="div"
                                        gutterBottom>
                                        {t("Contract.Title")}
                                    </Typography>

                                    <Typography className="contractSubtitle" variant="subTitle" component="div"
                                                gutterBottom>
                                        {t("Contract.SubTitle")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container sx={styles.contentLogo} justifyContent="flex-start">
                                <img width="114px" src={signContract}/></Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid mt={4} className={styles.toScroll}>
                            <Typography
                                align="justify"
                                variant="body"
                                component="div"
                                gutterBottom
                                className="contractText"
                            >
                                {HtmlText(
                                    t(getContractHtml(country, dataContract.person_type), {
                                        person_type: dataContract.person_type,
                                        name: dataContract.name,
                                        id_number: dataContract.id_number,
                                        ruc: dataContract.ruc,
                                        id_pdv: dataContract.id_pdv,
                                        email: dataContract.email,
                                        cell_number_epin: dataContract.cell_number_epin,
                                        cell_number_emoney: dataContract.cell_number_emoney,
                                        business_name: dataContract.business_name,
                                        legal_representative_name: dataContract.legal_representative_name,
                                        legal_representative_type: dataContract.legal_representative_type,
                                        day: day,
                                        month: month,
                                        year: year,
                                        due_year: due_year,
                                        timestamp: timestamp,
                                    })
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={styles.contentFooter}>
                    <Grid item xs={sizeGridController('gridTC', matches)}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow
                                        key={"0"}>
                                        <TableCellSyle width="10%" align="right" style={{verticalAlign: 'top'}}>
                                        </TableCellSyle>
                                        <TableCellSyle width="90%" align="center">
                                            <Typography className="contractText">
                                                <b> {t("Contract.PagareText")} </b>
                                            </Typography>
                                        </TableCellSyle>
                                    </TableRow>
                                    <TableRow
                                        key={"1"}>
                                        <TableCellSyle width="10%" align="right" style={{verticalAlign: 'top'}}>
                                            <Checkbox
                                                checked={checked}
                                                onChange={event => handleChange(event.target.checked, setChecked)}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                    "data-testid": "check-id",
                                                }}
                                            />
                                        </TableCellSyle>
                                        <TableCellSyle width="90%" align="left">
                                            <Typography variant="checkbox" className="contractText">
                                                {t("Contract.CheckboxText")}
                                                <Link href={t("Contract.Url")} target="_blank">
                                                    {t("Contract.LinkText")}
                                                </Link>
                                            </Typography>
                                        </TableCellSyle>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={sizeGridController('gridButtonContainer', matches)}
                          mb={sizeGridController('gridButtonMB', matches)}>
                        <Grid
                            container
                            direction="row"
                            justifyContent={sizeGridController('gridJustify', matches)}
                            alignItems="right"
                        >
                            <Grid item xs={sizeGridController('gridButton', matches)} md={5} lg={6}
                                  mt={sizeGridController('gridButtonMT', matches)}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    disabled={!checked}
                                    onClick={handleNext}
                                    className="action-button"
                                    data-testid="btn-continuar"
                                >
                                    {t("Contract.NextButton")}

                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>    
                    {( showDialog && !isMtsUser ) && (
                        <DialogTermsConditions 
                            setShowDialog={setShowDialog} 
                            checkNoticePrivacy={checkNoticePrivacy} 
                            checkTermsConditions={checkTermsConditions} 
                            setCheckNoticePrivacy={setCheckNoticePrivacy}
                            setTermsConditions={setTermsConditions}
                        />
                    )}
                </Grid>
            </Grid>

        </Fragment>
    );
}

export default function Contract() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    return (
        <div>
            {error && <MessageError path={"SignIn"}/>}
            {loading && <Loading/>}
            <div style={{display: (loading || error) ? "none" : "block"}}>
                <OnBoardingLayout screen={<ContractScreen
                    loading={loading}
                    setLoading={setLoading}
                    error={error}
                    setError={setError}
                />} cardNotificationNoPadding={true} rendersize={true}/>
            </div>
        </div>

    );
}