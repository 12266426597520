import axios from "axios";

const baseURL = process.env.REACT_APP_DOMAIN;
const headers = {
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_DOMAIN_APIKEY,
    Accept: "application/json",
    "Authorization": ''
  }
};
const error_response = {
  "status": 400,
  "message": "error"
}
export const contract_create = async (data) => {
  return axios
    .post(`${baseURL}/contract`, { ...data }, headers)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("contract_create error:" + err);
      return error_response;
    });
};

export const contract_sign = async (data) => {
  return axios
    .put(`${baseURL}/client`, { ...data }, headers)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("contract_sign error:" + err);
      return error_response;
    });
};

export const validate_token = async (data) => {
  return axios.post(`${baseURL}/token`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("validation error:" + err);
    return error_response;
  });
};

export const send_email_create_account = async (data) => {
  return axios.post(`${baseURL}/mail/account`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("send email error:" + err);
  
    return error_response;
  });
};

export const create_account = async (data) => {
  return axios.post(`${baseURL}/account`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("create account error:" + err);
    return error_response;
  });
};

export const reset_credentials = async (data) => {
  return axios.post(`${baseURL}/password`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
};
export const login = async (data) => {
  return axios.post(`${baseURL}/login`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("login error:" + err);
    return error_response;
  });
};

export const change_credentials = async (data) => {
  return axios.patch(`${baseURL}/password`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
};

export const send_email_credentials = async (data) => {
  return axios.post(`${baseURL}/mail/password`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
}

export const get_user_flow_type = async (id) => {
  return axios.get(`${baseURL}/user/${id}/flow`,
    headers).then((response) => {
      return response;
    }).catch((err) => {
      console.log("Error was occurred:" + err);
      return error_response;
    });
}

export const get_user_account_valid = async (param) => {
  return axios.get(`${baseURL}/validate/user?${param}`,
    headers).then((response) => {
      return response;
    }).catch((err) => {
      console.log("Error was occurred:" + err);
      return error_response;
    });
}

export const get_mts_account = async (param) =>{
  return axios.get(`${baseURL}/account/mts?${param}`,
      headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
}

export const updateToken = async (id, email) => {
  return axios.put(`${baseURL}/external_token?id=${id}`,
    { email: email },
    headers,
  ).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
}

export const getIntegrationToken = async (id) => {
  return axios.get(`${baseURL}/external_token?id=${id}`,
    headers,
  ).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
}

export const create_token_wc = async (data) => {
  return axios.post(`${baseURL}/create/token`, { ...data }, headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("create account error:" + err);
    return error_response;
  });
};

export const redirect = async () =>{
  return axios.get(`${baseURL}/gettest`,
      headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
}
export const get_token_mi_tienda = async (id) =>{
  return axios.get(`${baseURL}/external_token?id=${id}`,
      headers).then((response) => {
    return response;
  }).catch((err) => {
    console.log("Error was occurred:" + err);
    return error_response;
  });
}

export const validate_msisdn_exist = async (msisdn) =>{
  return axios.get(`${baseURL}/msisdn/${msisdn}/`,
      headers).then((response) => {
    return response;
  }).catch((_err) => {
    return error_response;
  });
}

export const get_user_record = async (agent) =>{
  return axios.get(`${baseURL}/record/${agent}/`,
      headers).then((response) => {
    return response;
  }).catch((err) => {
    if (err?.response?.status == 404)
      return err
    return error_response;
  });
}

export const update_user_record = async (data) =>{
  return axios.patch(`${baseURL}/record`,{ ...data }, headers).then((response) => {
    return response;
  }).catch((_err) => {
    return error_response;
  });
}