import React, {useEffect, useState, Fragment} from "react";
import HomeCredit from "../components/credit/homeCredit";
import {useCreditContext} from "../utils/providers/CreditProvider";
import UserInfoHeader from "../components/credit/userInfoHeader";
import {Stack} from "@mui/material";
import {ThemeBanner} from "../utils/themes/ThemeBanner";
import Loading from "./Loading"
import MessageError from "../components/loader/messageError";
import {approvalType} from "../constants/WowMoment";
import {useAPIContext} from "../utils/providers/APIProvider";
import {useSideMenuContext} from "../utils/providers/SideMenuProvider";

export default function Credit() {
    const {
        setNavigateConfig,
        setClient,
        clientId,
        session,
        setPaymentType,
        setPersistStatesContext
    } = useCreditContext();
    const {credit_line_data} = useAPIContext();
    const [data, setData] = useState("")
    const bannerStyles = ThemeBanner();
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)

    const {openSideMenu} = useSideMenuContext();

    const validateCredit = async () => {
        const response = await credit_line_data({params: `email=${session.email}&client_id=${clientId}`});
        if (response.status === 400) {
            setloading(false)
            seterror(true)
        } else {
            setPaymentType(approvalType.available)
            setData(response.data);
            setClient(response.data);
            setNavigateConfig({})
        }
    }

    useEffect(async () => {
        window.analytics.page('PAGEVIEW_HOME', {
            page: 'WC_Home',
            journey: 'WC_Loans',
            product_group: 'Lending',
            product_name: 'Working_Capital',
        });
        setloading(true)
        await validateCredit();
        setPersistStatesContext(true)
    }, []);

    return (
        <Fragment>
            {loading && <Loading></Loading>}
            {error && <MessageError path={'SignIn'}></MessageError>}
            {<div style={{display: (loading || error) ? "none" : "block"}}>
                {data && (
                    <Stack spacing={5} className={bannerStyles.bannerCredit}>
                        <UserInfoHeader onMenuItemsClick={openSideMenu} userdata={data} visibilityContent={true}/>
                        <HomeCredit props={data} validateError={seterror} loading={setloading} client_id={clientId}
                                    email={session.email}/>
                    </Stack>
                )}
            </div>
            }
        </Fragment>)
}
