import { createContext, useContext, useState } from "react";
import Loading from "../../views/Loading";
import MessageError from "../../components/loader/messageError";
import {
    UpdateSession,
    CreateSession,
    GetSession,
    StandarName,
    PersistSession
} from "./SessionManager";
import { HOST_, APIKEY_ } from './Host-ApiKey'

const creditContext = createContext();

export const CreditProvider = (props) => {
    const ClientSession = GetSession();
    const [PersistStatesContext, setPersistStatesContext] = useState(props.PersistStatesContext);
    PersistSession(PersistStatesContext, setPersistStatesContext, ClientSession);

    const AssingValue = (value) => {
        return props[value] ? props[value] : ClientSession?.[StandarName(value)];
    }
    const [AuthToken, setAuthToken] = useState(AssingValue('authToken'));
    const [Session, setStoreSession] = useState(AssingValue('session'));
    const [Credit, setCredit] = useState(AssingValue('credit'));
    const [Client, setClient] = useState(AssingValue('client'));
    const [Product, setProduct] = useState(AssingValue('product'));
    const [Products, setProducts] = useState(AssingValue('products'));
    const [SubProduct, setSubProduct] = useState(AssingValue('subProduct'));
    const [ProductName, setProductName] = useState(AssingValue('productName'));
    const [LoanAmount, setLoanAmount] = useState(AssingValue('loanAmount'));
    const [PendingApprovals, setPendingApprovals] = useState(AssingValue('pendingApprovals'));
    const [DueDate, setDueDate] = useState(AssingValue('dueDate'));
    const [PaymentType, setPaymentType] = useState(AssingValue('paymentType'));
    const [PaymentOptions, setPaymentOptions] = useState(AssingValue('paymentOptions'));
    const [Payment, setPayment] = useState(AssingValue('payment'));
    const [NavigateConfig, setNavigateConfig] = useState(AssingValue('navigateConfig'));
    const [CreateAccount, setCreateAccount] = useState(AssingValue('createAccount'));
    const [DisabledCredit, setDisabledCredit] = useState(AssingValue('disabledCredit'));
    const [IsAbbreviate, setIsAbbreviate] = useState(AssingValue('isAbbreviate'));
    const [HasActiveCredit, setHasActiveCredit] = useState(AssingValue('hasActiveCredit'));
    const [IsOverLimit, setIsOverLimit] = useState(AssingValue('isOverLimit'));
    const [DocumentStatus, setDocumentStatus] = useState(AssingValue('documentStatus'));
    const [ClientId, setClientId] = useState(AssingValue('clientId'));
    const [Flow, setFlow] = useState(AssingValue('flow'));
    const [Origin, setOrigin_] = useState(AssingValue('origin'));
    const [Error_, setError_] = useState(AssingValue('error_'));
    const [Loading_, setLoading_] = useState(AssingValue('loading_'));
    const [RedirectOut, setRedirectOut] = useState(AssingValue('redirectOut'));
    const [Host, setHost_] = useState(AssingValue('host'));
    const [Msisdn, setMsisdn] = useState(AssingValue('msisdn'));
    const [EpinNumber, setEpinNumber] = useState(AssingValue('epinNumber'));
    const [EmoneyNumber, setEmoneyNumber] = useState(AssingValue('emoneyNumber'));

    UpdateSession({
        AuthToken,
        Session,
        Credit,
        Client,
        Product,
        Products,
        ProductName,
        LoanAmount,
        PendingApprovals,
        SubProduct,
        DueDate,
        PaymentType,
        PaymentOptions,
        Payment,
        NavigateConfig,
        CreateAccount,
        Error_,
        Loading_,
        RedirectOut,
        DisabledCredit,
        IsAbbreviate,
        HasActiveCredit,
        IsOverLimit,
        DocumentStatus,
        Flow,
        ClientId,
        Origin,
        Host,
        Msisdn,
        EpinNumber,
        EmoneyNumber
    });

    const createSession = () => {
        CreateSession({
            Error_: false,
            Loading_: false,
            RedirectOut: true,
            IsAbbreviate: false,
            HasActiveCredit: false,
            IsOverLimit: false,
            DocumentStatus: "pending",
            Flow: "MOMO",
            Origin: 'Digital V2', 
            Session: { email: "", idpdv: "", is_abbreviate: "" }
        });
        setPersistStatesContext(true);
    }

    const setOrigin = (origin) => {
        if (origin) {
            setOrigin_(origin);
            return
        } 
        setOrigin_('default');
    };

    const setError = (error) => {
        if (error) {
            setLoading_(false);
        }
        setError_(error);
    };

    const setLoading = (loading) => {
        if (loading) {
            setError_(false);
        }
        setLoading_(loading);
    };

    const setSession = (session) => {
        localStorage.setItem("session", JSON.stringify(session));
        setStoreSession(session);
    };

    const setHost = (host) => {
        if (host) {
            setHost_(host);
            return
        } 
        setHost_('default');
    };
    return (<>

        <creditContext.Provider value={{
            disabledCredit: DisabledCredit,
            redirectOut: RedirectOut,
            error: Error_,
            loading: Loading_,
            session: Session,
            authToken: AuthToken,
            credit: Credit,
            client: Client,
            product: Product,
            products: Products,
            productName: ProductName,
            loanAmount: LoanAmount,
            pendingApprovals: PendingApprovals,
            subProduct: SubProduct,
            dueDate: DueDate,
            paymentType: PaymentType,
            paymentOptions: PaymentOptions,
            payment: Payment,
            navigateConfig: NavigateConfig,
            createAccount: CreateAccount,
            isAbbreviate: IsAbbreviate,
            hasActiveCredit: HasActiveCredit,
            isOverLimit: IsOverLimit,
            documentStatus: DocumentStatus,
            flow: Flow,
            clientId: ClientId,
            origin: Origin,
            host: Host,
            msisdn: Msisdn,
            epinNumber: EpinNumber,
            emoneyNumber: EmoneyNumber,
            setSession,
            setAuthToken,
            setSubProduct,
            setCredit,
            setClient,
            setProduct,
            setProducts,
            setProductName,
            setLoanAmount,
            setPendingApprovals,
            setDueDate,
            setPaymentType,
            setPaymentOptions,
            setPayment,
            setNavigateConfig,
            setCreateAccount,
            setClientId,
            setError,
            setLoading,
            setRedirectOut,
            setDisabledCredit,
            setIsAbbreviate,
            setHasActiveCredit,
            setIsOverLimit,
            setDocumentStatus,
            setFlow,
            setOrigin,
            createSession,
            setPersistStatesContext,
            getClientSession: GetSession,
            setHost,
            baseURL: HOST_[Host],
            APIKEY: APIKEY_[Host],
            setMsisdn,
            setEpinNumber,
            setEmoneyNumber
        }}>
            <div>
                {Error_ && <MessageError redirect={RedirectOut} />}
                {Loading_ && <Loading />}
                <div style={{ display: (Loading_ || Error_) ? "none" : "block" }}>
                    {props.children}
                </div>
            </div>
        </creditContext.Provider>
    </>
    )
}

export const useCreditContext = () => {
    return useContext(creditContext);
}